import { useMatches } from "@remix-run/react";
import { useMemo } from "react";

import type { User } from "~/models/user.server.ts";
import envsServer from "./envs.server";

const DEFAULT_REDIRECT = "/";

/**
 * This should be used any time the redirect path is user-provided
 * (Like the query string on our login/signup pages). This avoids
 * open-redirect vulnerabilities.
 * @param {string} to The redirect destination
 * @param {string} defaultRedirect The redirect to use if the to is unsafe.
 */
export function safeRedirect(
  to: FormDataEntryValue | string | null | undefined,
  defaultRedirect: string = DEFAULT_REDIRECT,
) {
  if (!to || typeof to !== "string") {
    return defaultRedirect;
  }

  if (!to.startsWith("/") || to.startsWith("//")) {
    return defaultRedirect;
  }

  return to;
}

/**
 * This base hook is used in other hooks to quickly search for specific data
 * across all loader data using useMatches.
 * @param {string} id The route id
 * @returns {JSON|undefined} The router data or undefined if not found
 */
export function useMatchesData(id: string): Record<string, unknown> | undefined {
  const matchingRoutes = useMatches();
  const route = useMemo(() => matchingRoutes.find((route) => route.id === id), [matchingRoutes, id]);
  return route?.data as Record<string, unknown>;
}

function isUser(user: any): user is User {
  return user && typeof user === "object" && typeof user.email === "string";
}

export function useOptionalUser(): User | undefined {
  const data = useMatchesData("root");
  if (!data || !isUser(data.user)) {
    return undefined;
  }
  return data.user;
}

export function useUser(): User {
  const maybeUser = useOptionalUser();
  if (!maybeUser) {
    throw new Error(
      "No user found in root loader, but user is required by useUser. If user is optional, try useOptionalUser instead.",
    );
  }
  return maybeUser;
}
/** @deprecated use a proper validation library instead like zod! */
export function validateEmail(email: unknown): email is string {
  console.warn("validateEmail is deprecated, use a proper validation library instead");
  return typeof email === "string" && email.length > 3 && email.includes("@");
}

export const setSecurityHeaders = (responseHeaders: Headers, html = true) => {
  // Security headers:
  responseHeaders.set("X-Frame-Options", "DENY");
  responseHeaders.set("X-Content-Type-Options", "nosniff");
  responseHeaders.set("X-XSS-Protection", "1; mode=block");
  responseHeaders.set("Referrer-Policy", "same-origin");
  if (html) {
    const csp = [
      `default-src 'self' https://*.googleapis.com https://i-sentry.hydra-newmedia.cloud ${
        envsServer.NODE_ENV !== "production" ? "ws://localhost:*" : ""
      }`,
      "script-src 'self' 'unsafe-inline' blob: https://i-sentry.hydra-newmedia.cloud",
      "style-src 'self' 'unsafe-inline' https://i-sentry.hydra-newmedia.cloud",
      "img-src 'self' data:",
    ];
    responseHeaders.set("Content-Security-Policy", csp.join(";"));
    responseHeaders.set(
      "Permissions-Policy",
      "camera=(), cross-origin-isolated=(), display-capture=(), document-domain=(), geolocation=(), microphone=(), payment=()",
    );
  }
  responseHeaders.set("Cross-Origin-Embedder-Policy-Report-Only", `require-corp; report-to="default"`);
  responseHeaders.set("Cross-Origin-Opener-Policy-Report-Only", `same-origin; report-to="default"`);
  responseHeaders.set("Cross-Origin-Resource-Policy", `same-site; report-to="default"`);
  responseHeaders.set("Expect-CT", `max-age=0, report-uri="https://hydra.report-uri.com/r/d/ct/reportOnly"`);
  // report to https://hydra.report-uri.com/r/d/csp/reportOnly
  responseHeaders.set(
    "Report-to",
    JSON.stringify({
      group: "default",
      max_age: 31536000,
      endpoints: [{ url: "https://hydra.report-uri.com/a/d/g" }],
      include_subdomains: true,
    }),
  );
  // HSTS
  if (envsServer.NODE_ENV === "production") {
    responseHeaders.set("Strict-Transport-Security", "max-age=31536000; includeSubDomains");
  }
};
